import React from 'react';
import { CDN_ADDRESS } from '../../server_address';
import Link from 'next/link';
import SearchBox from './SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import UserMenu from '../menu/UserMenu';
import { Button, Divider, Hidden } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { Close, List as ListIcon, Menu, Search } from '@material-ui/icons';
import { getTranslation, useTranslation } from '../../translation/translations';

import { initTranslationBrowser } from '../../translation/translation-browser';
import { getRedirectPath } from '../authentication/loginHelper';
import Auth0AuthenticationService from '../../services/authentication/auth0-authentication.service.lazy';
import { useAuth } from '../../services/authentication/auth.context';
import { useModalState } from './modal/hooks/useModal';

interface UiHeaderProps {
  isHomepage: boolean;
}

const container = typeof window !== 'undefined' ? () => window.document.body : undefined;

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
  },
}));

const UiHeader = (props: UiHeaderProps) => {
  const router = useRouter();

  const auth = useAuth();

  const { userInfo } = auth;

  const loading = auth.authInProgress;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const classes = useStyles();

  const login = async () => {
    await auth.login({ promptOnFail: true, redirectUri: getRedirectPath(router) });
  };

  const logout = async () => {
    const logoutUrl =
      Auth0AuthenticationService.authenticationConfig.logoutUrl +
      (router.locale && router.locale !== 'en' ? '/' + router.locale : '');
    await auth.logout(logoutUrl);
  };

  initTranslationBrowser();

  const { t } = useTranslation();
  const getT = getTranslation().t;

  const { handleModal, modalOpen } = useModalState();

  const referralProgramPopupContents = () => {
    return (
      <div className="io-modal">
        <div className="io-modal-title">
          <h5>Referral Program Title</h5>
          <IconButton className="io-modal-close-button" size="small" onClick={() => handleModal()}>
            <Close />
          </IconButton>
        </div>
        <div className="io-modal-body">
          <p>Referral program description</p>
        </div>
        <div className="io-modal-footer">
          <Button variant="outlined" color="primary" onClick={() => handleModal()}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  const onOpenReferralProgram = () => {
    if (!modalOpen) {
      handleModal(referralProgramPopupContents());
      handleDrawerClose();
    }
  };

  const MenuItems = () => (
    <>
      <li data-locale={router.locale} data-t1={getT('/stocks')} data-t2={t('/stocks')} className="menu-item">
        <Link href={t('/portfolio')}>
          <a className="menu-link">
            <span className={'menu-icon-hr'}>
              <FontAwesomeIcon className="menu-icon1" icon="eye" />
            </span>
            <span className="menu-text">{t('menu-watchlist')}</span>
          </a>
        </Link>
      </li>
      <li className="menu-item">
        <Link href={t('/screener')}>
          <a className="menu-link">
            <span className={'menu-icon-hr'}>
              <FontAwesomeIcon className="menu-icon1" icon="bars" />
            </span>
            <span className="menu-text">{t('menu-screener')}</span>
          </a>
        </Link>
      </li>
      {/*<li className="menu-item menu-item-green">
        <Link href="/green">
          <a className="menu-link">
            <span className={'menu-icon-hr menu-icon-hr-green'}>
              <FontAwesomeIcon className="menu-icon1" icon="leaf" />
            </span>
            <span className="menu-text">{t('menu-green')}</span>
          </a>
        </Link>
      </li>*/}
    </>
  );

  const MobileMenu = () => (
    <>
      <List>
        <div style={{ display: 'flex' }}>
          <div className="mobile-header-searchbox">
            <SearchBox short={true} icon={<Search />} />
          </div>
          <div className="mobile-header-close">
            <IconButton onClick={handleDrawerClose} size="small">
              <Close />
            </IconButton>
          </div>
        </div>
        <Divider />
        <Link href={t('/portfolio')} passHref={true}>
          <ListItem
            button={true}
            onClick={(ev) => {
              handleDrawerClose();
              return false;
            }}
            component="a"
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="eye" style={{ fontSize: '22px' }} />
            </ListItemIcon>
            <ListItemText primary={t('menu-watchlist')} />
          </ListItem>
        </Link>
        <Link href={t('/screener')} passHref={true}>
          <ListItem
            button={true}
            onClick={(ev) => {
              handleDrawerClose();
              return false;
            }}
            component="a"
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu-screener')} />
          </ListItem>
        </Link>
        {/*<Link href={'/green'} passHref={true}>
          <ListItem
            button={true}
            onClick={(ev) => {
              handleDrawerClose();
              return false;
            }}
            component="a"
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="leaf" style={{ fontSize: '22px' }} />
            </ListItemIcon>
            <ListItemText primary={t('menu-green')} />
          </ListItem>
        </Link>*/}
        <UserMenu
          isSidebar={true}
          loading={loading}
          walletUser={auth.loggedInViaWallet ? auth.walletAccount : null}
          userInfo={userInfo}
          onLogin={login}
          onLogout={logout}
          handleDrawerClose={handleDrawerClose}
          onOpenReferralProgram={onOpenReferralProgram}
          onPathSelected={(val: string) => router.push(val)}
        />
      </List>
    </>
  );

  return props.isHomepage ? (
    <header className="ui-header-index">
      <div className="ui-header-index-container">
        <div className="logo">
          <div className="logo-image">
            <Link href="/">
              <img src={CDN_ADDRESS + 'logo_header_small.png'} width="48" height="48" alt="iOCharts" />
            </Link>
          </div>
          <div className="logo-name">
            <h1>
              <Link href="/">iO Charts</Link>
            </h1>
          </div>
        </div>

        <div className="menu">
          <UserMenu
            loading={loading}
            userInfo={userInfo}
            walletUser={auth.loggedInViaWallet ? auth.walletAccount : null}
            onLogin={login}
            onLogout={logout}
            onOpenReferralProgram={onOpenReferralProgram}
            onPathSelected={(val: string) => router.push(val)}
          />
        </div>
      </div>
    </header>
  ) : (
    <header className="ui-header-normal">
      <div className="ui-header-index-container">
        <div className="logo">
          <div className="logo-image">
            <Link href="/">
              <img src={CDN_ADDRESS + 'logo_header_small.png'} width="48" height="48" alt="iOCharts" />
            </Link>
          </div>
          <div className="logo-name">
            <Link href="/">iO Charts</Link>
          </div>
        </div>

        <Hidden mdDown={true}>
          <ul className="menu">
            <MenuItems />
          </ul>
        </Hidden>

        <Hidden mdDown={true}>
          <div className="menu-searchbox-container">
            <SearchBox />
          </div>
        </Hidden>

        <Hidden xsDown={true} lgUp={true}>
          <div className={'searchbox-mobile'}>
            <div className="menu-searchbox-container">
              <SearchBox />
            </div>
          </div>
        </Hidden>

        <Hidden mdDown={true}>
          <UserMenu
            loading={loading}
            walletUser={auth.loggedInViaWallet ? auth.walletAccount : null}
            userInfo={userInfo}
            onLogin={login}
            onLogout={logout}
            onOpenReferralProgram={onOpenReferralProgram}
            onPathSelected={(val: string) => router.push(val)}
          />
        </Hidden>

        <Hidden lgUp={true}>
          <div className={'menu menu-toggleicon'}>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </div>
        </Hidden>

        <Hidden lgUp={true}>
          <Drawer
            style={{ width: '200px' }}
            /*container={container}*/
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <MobileMenu />
          </Drawer>
        </Hidden>
      </div>
    </header>
  );
};

export default UiHeader;
